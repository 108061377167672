import React, { useEffect, useState } from 'react';
import './Hero.css';

const commands = [
    'git add .',
    'git commit -m "Implement cutting-edge solutions 🌟"',
    'npm install && npm run build',
    'npm test --watchAll=false',
    'echo "Deploying with FrothyHog’s secret sauce..."',
    'git push origin main',
    'ssh pepega@server "cd /var/www && git pull origin main && npm ci && pm2 restart all"',
    'echo "Success! Your future is live and thriving 🚀"'
];

const Hero = () => {
    const [displayedText, setDisplayedText] = useState("");
    const [currentCommandIndex, setCurrentCommandIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        if (currentCommandIndex < commands.length) {
            const currentCommand = commands[currentCommandIndex];

            const timer = setInterval(() => {
                if (charIndex < currentCommand.length) {
                    setDisplayedText(prev => prev + currentCommand[charIndex]);
                    setCharIndex(prev => prev + 1);
                } else {
                    clearInterval(timer);
                    setTimeout(() => {
                        setDisplayedText(prev => prev + "\n");
                        setCurrentCommandIndex(prev => prev + 1);
                        setCharIndex(0);
                    }, 500);
                }
            }, 100);

            return () => clearInterval(timer);
        }
    }, [charIndex, currentCommandIndex]);

    return (
        <section className="hero-bar">
            <div className="hero-content">
                <pre className="terminal">
                    <span>{displayedText}</span>
                    <span className="blinking-cursor">|</span>
                </pre>
            </div>
        </section>
    );
};

export default Hero;
