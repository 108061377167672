import Hero from './Hero/Hero';
import About from './About/About';
import FeaturedCollections from './FeaturedCollections/FeaturedCollections';
import Highlights from './Highlights/Highlights';
import CallToAction from './CallToAction/CallToAction';

const HomePage = () => {
    return (
        <>
            <Hero />
            <About />
            <Highlights />
            <CallToAction />
        </>
    );
};

export default HomePage;