import React from 'react';
import './Highlights.css';

const Highlights = () => {
    return (
        <section className="simple-highlights">
            <div className="highlight-item">
                <h1>10+</h1>
                <p>Projects Deployed</p>
            </div>
            <div className="highlight-item">
                <h1>20+</h1>
                <p>Years of Industry Experience</p>
            </div>
            <div className="highlight-item">
                <h1>:)</h1>
                <p>Cups of coffee</p>
            </div>
        </section>
    );
};

export default Highlights;
