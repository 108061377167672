import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';

function ContactUs() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaLoad = () => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          setCaptchaToken(token);
        });
      });
    } else {
      console.error('reCAPTCHA not loaded yet');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert('Please verify the captcha.');
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      { ...formData, 'g-recaptcha-response': captchaToken },
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
    .then((result) => {
      console.log(result.text);
      alert('Your message has been sent successfully!');
    })
    .catch((error) => {
      console.error(error.text);
      alert('There was an error sending your message.');
    });

    setFormData({ name: '', email: '', message: '' });
    setCaptchaToken(null);
  };

  useEffect(() => {
    if (window.grecaptcha) {
      handleCaptchaLoad();
    } else {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      recaptchaScript.async = true;
      recaptchaScript.onload = handleCaptchaLoad;
      document.body.appendChild(recaptchaScript);
    }
  }, []);
  
  return (
    <div className='main-container'>
      <h2>Contact Us</h2>
      <form className='contact-form' onSubmit={handleSubmit}>
        <label htmlFor='name'>Name:</label>
        <input 
          type='text' 
          id='name' 
          name='name' 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        
        <label htmlFor='email'>Email:</label>
        <input 
          type='email' 
          id='email' 
          name='email' 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        
        <label htmlFor='message'>Message:</label>
        <textarea 
          id='message' 
          name='message' 
          rows='5' 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />

        <button type='submit' className='submit-button'>Send Message</button>
      </form>
    </div>
  );
}

export default ContactUs;
