import React from 'react';
import './About.css';
import aboutImage from "../../../assets/aboutImage.jpg";

const About = () => {
    return (
        <section className="about">
            <div className="about-content">
                <div className="about-text">
                    <h2>About FrothyHog</h2>
                    <p>At FrothyHog, we're not just a tech team—we're the Swiss Army knife for your business, equipped with the versatility to solve any challenge. We work as an extension of your team, diving deep into your unique needs to craft custom solutions that drive your business forward. Our remote-first approach allows us to collaborate seamlessly across borders, ensuring that every solution we deliver not only meets your current needs but also scales with your future growth.</p>
                    <p>But we're more than just experts—we're a fun and engaging team that values long-term partnerships. We believe in building relationships where collaboration feels more like friendly teamwork than a business transaction. When you work with FrothyHog, you're gaining a dedicated partner who cares about your success and makes the journey enjoyable.</p>
                </div>
                <div className="about-image">
                    <img src={aboutImage} alt="About FrothyHog"/>
                </div>
            </div>
        </section>
    );
};

export default About;
