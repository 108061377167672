import React from 'react';
import './CallToAction.css';

const CallToAction = () => {
    return (
        <section className="call-to-action">
            <div className="cta-card">
                <div className="cta-card-inner">
                    <div className="cta-card-front">
                        <h2>Wondering Why We're Called FrothyHog?</h2>
                    </div>
                    <div className="cta-card-back">
                        <p>Subscribe to our blog and be the first to learn our secrets, tips, and the latest trends from the FrothyHog team.</p>
                        <a href="https://medium.com/frothyhog" target="_blank" rel="noopener noreferrer" className="cta-button">Subscribe Now</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CallToAction;
