import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className='main-container'>
      <div className='hero-container'>
        <h1>Our Services</h1>
        <p>At FrothyHog, we offer a wide range of services to help bring your digital projects to life. From automation to full-stack development, our team of experts is here to support your business every step of the way.</p>
      </div>
      <div className='services-container'>
        <div className='service-card'>
          <h3>QA Automation</h3>
          <p>Streamline your testing process with our comprehensive API and UI automation services, ensuring high-quality software delivery.</p>
        </div>
        <div className='service-card'>
          <h3>Mobile App Development</h3>
          <p>Develop high-performance mobile applications for iOS and Android that engage users and enhance your business.</p>
        </div>
        <div className='service-card'>
          <h3>Fullstack Development</h3>
          <p>Build scalable and robust web applications from frontend to backend with our fullstack development services.</p>
        </div>
        <div className='service-card'>
          <h3>Game Development</h3>
          <p>Design and develop engaging and immersive games for various platforms, tailored to your vision and goals.</p>
        </div>
        <div className='service-card'>
          <h3>Consulting Services</h3>
          <p>Receive expert advice and guidance to navigate complex technical challenges and drive your projects to success.</p>
        </div>
        <div className='service-card'>
          <h3>Custom Quote</h3>
          <p>Have a unique project or specific needs? Contact us for a custom quote tailored to your requirements. We're prepared to walk with you from idea to launch.</p>
        </div>
      </div>
    </div>
  );
}

export default Services;
